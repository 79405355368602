import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FormPage from "../components/form-page"
import { graphql } from "gatsby";
import Image from "gatsby-image";

export default function BeAVolunteer({data}) {
  return (
    <FormPage data={data} />
  )
}

export const pageQuery = graphql`
  query BeAVolunteer {
    wpPage(id: { eq: "cG9zdDozOA==" }) {
      featuredImage {
        node {
          title
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
